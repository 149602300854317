export default {
    development: {
        bucket: 'local-number-400',
    },
    test: {
        bucket: 'test-number-400',
    },
    production: {
        bucket: 'prod-number-400',
    }
}

<template>
    <van-uploader v-model="fileList"
                  :after-read="afterRead"
                  :before-read="getSignature"
                  :max-count="isMultiple ? undefined : 1"
                  :multiple="isMultiple"
                  v-bind="$attrs"
                  @delete="onDelete"
                  @oversize="onOversize"
    />
</template>

<script>
    import {getAliOssSignature} from "@/api/openOrder";
    import Axios from "axios";
    import {uuid, bytesToSize} from "@/utils/tools";
    import config from "./config";
    const {bucket} = config[process.env.NODE_ENV];

    export default {
        name: "MyUpload",
        props: {
            value: [Array, Object],
            params: Object
        },
        model: {
            prop: 'value',
            event: 'update'
        },
        data() {
            return {
                option: {
                    host: '',
                    data: {},
                    raw: {}
                },
                fileList: []
            }
        },
        computed: {
            isMultiple() {
                return Array.isArray(this.value);
            },
        },
        watch: {
            value: 'getFileList'
        },
        beforeDestroy() {
            this.option = {host: '', data: {}, raw: {}};
            this.fileList = [];
        },
        mounted() {
            this.getFileList();
        },
        methods: {
            // 超出文件限制大小
            onOversize() {
                let maxSize = bytesToSize(this.$attrs['max-size']);
                this.$toast.fail(`文件大小不能超过 ${maxSize}`);
            },
            // 删除事件
            onDelete(file, {index}) {
                if (!this.isMultiple) {
                    this.$emit('update', {name: '', url: '', src: ''});
                } else {
                    this.value.splice(index, 1);
                    this.$emit('update', this.value);
                }
            },
            // 获取预览列表
            getFileList() {
                let value = (Array.isArray(this.value) ? this.value : [this.value]).filter(v => v.url);
                if (value.length > 0) {
                    this.fileList = value.map(v => ({url: v.url}));
                } else {
                    this.fileList = [];
                }
            },
            afterRead(file) {
                file.status = 'uploading';
                file.message = '上传中...';
                let {host, data} = this.option;
                let formData = new FormData();
                Object.keys(data).forEach(key => formData.append(key, data[key]));
                // 文件数据放最后
                formData.append('file', file.file);
                Axios({
                    url: host,
                    method: 'post',
                    data: formData
                }).then(() => {
                    file.status = 'success';
                    file.message = '';
                    let {raw} = this.option;
                    if (this.isMultiple) {
                        this.$emit('update', [...this.value, raw]);
                    } else {
                        this.$emit('update', raw);
                    }
                }).catch(() => {
                    file.status = 'failed';
                    file.message = '上传失败';
                });
            },
            // 获取上传配置信息
            getSignature(file) {
                let _uuid = uuid();
                let ext = file.name.split('.').pop();
                let fileName = `${_uuid}.${ext}`;
                return new Promise((resolve, reject) => {
                    getAliOssSignature({bucket, ...this.params}).then(res => {
                        let {host, dir, policy, accessid, signature} = res.data;
                        let key = dir + fileName;
                        this.option.host = host;
                        this.option.data = {
                            key,
                            policy,
                            OSSAccessKeyId: accessid,
                            success_action_status: 200,
                            signature,
                        };
                        this.option.raw = {name: fileName, url: `${host}/${key}`, src: ''} ;
                        resolve();
                    }).catch(reject);
                });
            }
        }
    }
</script>

<style scoped>

</style>
